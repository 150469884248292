.sales-targets-container {
  display: flex;
  flex-direction: row-reverse;
  padding-right: 10px;
  .source-metrics {
    // flex: 1;
    margin-left: 20px;
    position: relative;
    padding-left: calc(23px + 6px);
    .source-metrics-label {
      position: absolute;
      top: 0;
      left: calc(23px + 6px);
      transform-origin: 0 0;
      transform: rotate(90deg);
      background-color: silver;
      width: 110px;
      height: 4ex;
      padding-left: 0px;
      padding-right: 0px;
      text-align: center;
      padding-top: 6px;
    }
    .source-metrics-content {
      display: flex;
      height: 110px;

      .metric-box {
        padding-left: 10px;
        padding-right: 10px;
        width: 150px;
        margin-left: 6px;
        border: solid 1px gray;
        .metric-label {
          text-align: center;
        }
        .metric-values {
          .actual {
            font-size: 200%;
          }
        }
      }
    }
  }

  .below_min_target, .high_ecpa {
    .actual, .target, .min_target {
      color: red;
    }
  }
}