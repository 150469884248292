.dashboard-container {
  display: grid;
  grid-template-columns: [col-1] 50% [col-2] auto;

  grid-template-rows: [row-header] minmax(100px, max-content) [row-ouisys-summary] 260px [row-summary] 400px [row-arpu] 300px [row-affiliates] 400px [row-gateways] 400px [row-operators] 400px [row-services] 400px;
  &.with-targets {
    grid-template-rows: [row-header] minmax(100px, max-content) [row-targets] 100px [row-ouisys-summary] 260px [row-summary] 400px [row-arpu] 300px [row-affiliates] 400px [row-gateways] 400px [row-operators] 400px [row-services] 400px;
  }
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  // grid-auto-columns: 50% auto;
  // grid-auto-rows: 400px;

  .dashboard-box {
    position: relative;
    // outline: dashed 1px red;

    --row: span 1;
    --column: span 1;
    --header-height: 32px;
    --content-margin-top: 4px;
    --content-bottom: 12px;
    --border: 1px;

    grid-row: var(--row, span 1);
    grid-column: var(--column, span 1);
    border: solid var(--border) #eee;

    &.dashboard-head {
      display: flex;
      flex-wrap: wrap;
      z-index: 2000;
    }

    &.split-top-bottom {
      height: 400px;
      .top {
        position: absolute;
        top: 0;
        bottom: 50%;
        left: 0;
        right: 0;
      }
      .bottom {
        position: absolute;
        top: 50%;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }

    .header {
      background-color: #ddd;
      border-bottom: 0;
      text-align: center;

      display: flex;
      justify-content: space-between;
      align-content: center;
      align-items: center;
      

      min-height: var(--header-height);

      * {
        flex: 1;
      }

      .controls {
        text-align: right;
        font-size: smaller;
      }
      .title {
        text-align: left;
        padding-left: 12px;
        flex-grow: 1;
      }
    }

    .content {
      position: absolute;
      top: calc(var(--header-height) + var(--content-margin-top)); 
      bottom: var(--content-bottom); 
      left: 0; right: 0; z-index: 1000;
      overflow: hidden;
    }
  }
}