.data-table-container {
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  overflow: auto;

  table {
    width: 100%;
    margin: 0;
    padding: 0;
    border-collapse: collapse;

    th {
      position: sticky;
      top: 0;
      background: white;
    }
    td {
      border: solid 1px #eee;
      text-align: left;
      padding-left: 0.5em;

      &:nth-child(1), &.label{
        text-align: left;
        padding-left: 0;
      }
      &.label:nth-child(1) {
        padding-left: 0.5em;
      }

      &.change:after {
        content: var(--change);
        float: right;
        font-size: 45%;
        font-weight: bold;
        color: var(--color);
      }
    
    }

    &.enlarge-first {
      tbody tr:first-child td {
        font-size: 140%;
      }
    }
  }
}