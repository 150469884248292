html, body, *, * {
  scroll-behavior: smooth;
  // -webkit-touch-callout: none;
  // -webkit-tap-highlight-color: transparent;
  // -webkit-user-select: none;
  font-size: 16px;
}

input, select, button, option {
  font-size: 14px;
}

.game-full-screen {
  position: fixed; top: 0; left: 0; right: 0; bottom: 0;

  iframe {
    position: fixed; top: 0; left: 0; right: 0; bottom: 0;
  }
  // &.standalone {
  //   top: 50px;
  //   iframe {
  //     top: 50px;
  //   }  
  // }
}