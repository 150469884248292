.row {
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  background-color: #999;
  box-sizing: border-box;
  flex-direction: column;

  .date {
    display: 100%;
    padding: 0 0 0 0em;
    margin: 0;
    text-align: left;
    background-color: #222;
    color: #bbb;
    font-size: 80%;
  }

  .metrics {
    display: flex;
    width: 100%;
  

    .metric {
      flex: 1;

      text-align: left;
      font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
      // line-height: calc(1.3em + (1.0 - 0.2) * ((100vw - 300px)/(1600 - 300)));
      // margin-left: calc(0.5em + (1.0 - 0.2) * ((100vw - 300px)/(1600 - 300)));
      display: flex;
      &:nth-child(2) {
        margin-left: 0;
      }

      background: #333;
      color:white;
      border: solid 1px black;

      .label {
        background-color: black;
        font-size: 80%;
        flex-grow: 0;
        flex-shrink: 0;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        padding: 0.3em;
      }

      .value {
        padding-left: 1em;
        font-size: 110%;
        flex-grow: 1;
        flex-shrink: 0;
      }

      .change {
        font-size: 80%;
        padding-right: 1em;
        &.n {
          color: #b9acac;
        }
        &.p {
          color: #99af99;
        }
      }
    }
  }
}

body.no-scroll {
  overflow: hidden;
}

// table
table {
  --selected-bg-color: rgba(253, 242, 144, 0.486);
  tr[data-row] {
    &:hover{
      background-color: var(--selected-bg-color);
    }

    td[data-col="1"] {
      font-weight: bold;
    }
  }
}

@for $i from 1 through 20 {
  table[data-selected-col="#{$i}"] [data-col="#{$i}"] {
    background-color: var(--selected-bg-color);
  }
}